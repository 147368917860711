* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden !important ;
  overflow-y: scroll !important ;
  padding: 0px !important ;
}

button {
  background-color: transparent;
  border: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
li,
button,
a {
  direction: rtl;
}

.universal-logo {
  height: 31.01px;
  width: auto;
  margin-top: 80px;
}
.universal-logo-top-distance {
  margin-top: 28px;
}
/* validation css start */
.show-error-message {
  color: #ee2c46;
}
.img-blur-oncheked-box-click {
  backdropfilter: blur(3px);
  backgroundcolor: rgba(0, 0, 30, 0.4);
}

/* validation css end */
.universal-matchup-screen-box-width-set {
  max-width: 100vw !important;
}

.splash-scren-row-box {
  height: 100vh;
}
.splash-set-bg-main-home {
  background-color: #ee2c46;
  z-index: 0;
}
.universal-background-top {
  width: 100%;
  position: relative;
  z-index: -1;
}
img.universal-background-top-img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.universal-background-middel-star {
  width: 300px;
  position: relative;
  height: auto;
  z-index: -1;
}
img.universal-background-middel-star-img {
  width: 100%;
}
.universal-background-bottom {
  bottom: 0px;
  height: auto;
  position: absolute;
  right: 0;
  width: 300px;
  z-index: -1;
}
.select-detail-universal-bac-bottum {
  bottom: -28px;
}
img.universal-background-bottom-img {
  width: 100%;
}
.universal-row-box {
  height: 100%;
  padding-bottom: 100px;
  min-height: 100vh;
}
.row.main-home-gender2-page {
  padding-bottom: 45px;
}
.universal-row-box1 {
  height: 100%;
}

.text-1 {
  font-weight: 800;
  font-size: 26px;
  color: #404040;
}

p {
  margin: 0px;
}

.matchup-col {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  min-height: 100vh;
}
.col-12.matchup-col.matchup2 {
  min-height: auto;
  z-index: 0;
}
.button-div {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.splash-button-div {
  margin-top: 40px;
}

.button-accept {
  width: 283px;
  height: 51px;
  background-color: #ee2c46;
  border-radius: 50px;
  border: none;
  margin-top: 15px;
  padding: 13px 45px;
  font-size: 1rem;
  white-space: nowrap;
  color: white;
  font-weight: bold;
  position: relative;
}

.button-accept i {
  color: white;
  position: absolute;
  right: 10px;
}

.button-blank-box {
  background-color: transparent;
  border: 1px solid white;
  color: #ee2c46;
}

.select-detail-button-blank-box {
  border: 1px solid #ee2c46;
}

button.button-accept.upload-file-button {
  width: 195px;
  height: 51px;
}

.gallery-peh button.button-accept.upload-file-button {
  width: 195px;
  height: 51px;
  margin-bottom: 50px;
}

button.profile-button.button-accept {
  margin-top: 16px;

  width: 283px;
  height: 51px;
}

.select-page-pehragraph-text {
  margin-top: 15px;
  font-size: 26px;
  font-weight: 800;
}

.profile-para {
  margin-top: 70px !important;
  line-height: 40px;
  color: #404040;
  font-weight: 700;
  font-size: 26px !important;
  font-weight: bold;
  width: 70%;
  margin: 0 auto;
}

.profile-para-2 {
  padding: 10px 0;
}

.profile-button {
  width: 250px;
}
.reactEasyCrop_CropAreaGrid::before {
  border: transparent !important;
}
.reactEasyCrop_CropArea {
  border: 1px solid #ee2c46 !important;

  box-shadow: 0 0 0 9999em;
}
.reactEasyCrop_CropAreaGrid::after {
  border: transparent !important;
}
.upload-gallery-main-img {
  height: 156.92px;
  width: 178px;
  margin: 20px 0 10px 0px;
}

p.last-text {
  padding: 10px 0;
  font-size: 1.6rem;
}

img.logo-1 {
  width: 180px;
  margin-top: 270px;
}

.splash-logo-div {
  margin-top: 10%;
}

.text-logo-div-splash p {
  margin: 6px 0;
}

.gender2-text {
  padding-top: 0;
  font-size: 1.5rem;
}
.select-detail-star-background {
  margin-top: 75px;
}

img.icon-gende {
  width: 76px;
  margin: 0 15px;
}

.gender-icon {
  padding: 105px 0px;
}

.button-gender {
  background-color: #ee2c46;
  border-radius: 50px;
  border: none;
  padding: 10px 35px;
  font-size: 1.2rem;
  margin: 35px 0;
}

button.btn.btn-primary.button-genders {
  width: 283px;
  height: 50px;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  background-color: grey;
  border-radius: 50px;
  border: none;
}

.outside-div-for-icons {
  width: 93px;
  height: 92px;

  margin: 5px;
  position: relative;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.col-4.image-upload-4 {
  border: 2px solid rgba(238, 44, 70, 0.15);
  overflow: hidden;
  width: 93px;
  height: 93px;
  border-radius: 5px;
  background-color: #fef8f8;
}

.list-btn-data-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  padding: 80px 0;
}

.list-btn-data-box li {
  list-style: none;
  padding: 7px 12px;
  border: 1px solid gray;
  margin: 6.5px;
  border-radius: 44px;
  white-space: nowrap;
}

.list-btn-data-box li a {
  font-size: 0.8rem;
}

.list-btn-data-box li a:hover li {
  background-color: green;

  width: 100%;
}

.gender2-text2 {
  font-size: 14px;
  color: #9a9a9a;
  font-weight: 400;
}

.uploadImage_gallery {
  justify-content: center;
  align-items: center;
  padding-bottom: 90px;
}

.row.upload-row-image {
  justify-content: center;
  align-items: center;
  margin-top: 35px;
}

.image-group1 .button-div {
  margin: 0px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.modal-dialog {
  width: 324px;
  height: 304px;
  margin: 1.75rem auto;
}

.modal-body button.remove-btn-close-cross {
  width: 30px;
  height: 30px;
  padding: 0;

  opacity: 1;
  color: white;
  background-image: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -8px;
  right: -4px;
}

.modal-header {
  border-bottom: none;
}
.main-img-heartpopup.main-img-existing-popup {
  width: 200px;
  height: auto;
  margin-bottom: 10px;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.main-home.location {
  background-image: url("../Assets/Images/select_location.png");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  position: relative;
  height: 100vh !important;
  min-height: 680px !important;
}

.main-home.user-details-select {
  background-image: url("../Assets/Images/select-tags-bg.png");
}
.all-data-center-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 13px;
}
.home-body-bottum-box.all-data-center-box {
  margin-top: 60px;
}
.home-vector-screen-1 {
  height: 115.14px;
  width: auto;
}

.main-home .home-body-box {
  padding: 20px 0 30px 0;
}

.main-home .home-body-box h2 {
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  margin-top: 55px;
  width: 85%;
  line-height: 34px;
}

p.homepage-description {
  text-align: center;
  width: auto;
  padding: 15px 0;
  margin: 0;
  line-height: 28px;
  font-size: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

h2.home-title-2 {
  font-size: 26px !important;
  width: 100% !important;
  font-weight: 700 !important;
}

.description-2-red {
  color: #ee2c46;
}

p.homepage-description:nth-child(1) {
  font-weight: 400;
}

p.homepage-description:nth-child(2) {
  font-weight: 400;
}

p.homepage-description span:nth-child(3) {
  font-weight: 700;
}

p.homepage-description span:nth-child(4) {
  font-weight: 700;
}

h4.mt-4.home-sub-heading {
  font-weight: 700;
  color: #ee2c46;
  font-size: 26px;
}

.main-home .home-body-box .question-box-peh-home {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  margin-top: -5px;
  border-radius: 5px;
  padding: 15px;
  background-color: #fef8f8;
}
.main-home .home-body-box .question-box-peh-home .question-box-peh-home-top {
  font-size: 14px;
  line-height: 20px;
  padding: 0px;
  margin: 0px;
  border-radius: 5px;
  width: 100%;
  background-color: #fef8f8;
  font-weight: bold;
}
.main-home .home-body-box h4 {
  text-align: center;
  padding: 20px 0 0 0;
  color: #ee2c46;
  font-weight: 800;
  margin: 0;
  font-size: 26px;
}

.main-home .home-body-box .home-body-bottum-box img {
  width: 25px;
  height: auto;
  z-index: 11;
}

.main-home .home-body-box .home-body-bottum-box .home-body-main-img-box img {
  width: 155px;
  height: auto;
  margin: 0 auto;
}
.main-home-text-container p {
  padding: 15px 20px 20px 15px;
  font-size: 0.8rem;
}

.main-home-text-container p span {
  font-size: 16px;
  font-weight: 700;
}

.main-home-video-container {
  padding-bottom: 10px;
}
button.btn.btn-primary.fill-button-universal {
  width: 283px;
  height: 50px;
  padding: 12px 30px;
  background-color: #ee2c46;
  border: none;
  border-radius: 50px;
  font-weight: bold;
}

button.btn.btn-primary.fill-button-universal.home-page-btn {
  font-size: 16px !important;
  font-weight: 700;
  line-height: 22px;
}

i.bi.bi-check-circle {
  color: #ee2c46;
  width: 22px;
  height: 22px;
}

button.btn.btn-primary.submit-button-check {
  color: black;
  width: 283px;
  height: 50px;
  padding: 12px 30px;
  background-color: transparent;
  border-radius: 50px;
  font-weight: bold;
  border-color: black;
  position: relative;
}

button.btn.btn-primary.submit-button-check .text-icon i {
  position: absolute;
  right: 10px;
}

.home-footer-box p {
  padding: 15px 0;
  margin: 0;
  font-size: 13px;
}

.home-footer-box p a {
  color: black;
}

.home-footer-link-box a {
  padding: 35px 0;
  color: #dd3a3a;
  font-size: 18px;
  font-weight: 800;
}

.main-home .home-body-box .number-card-peh-text {
  padding: 0px;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
}

h2.home-title-2 {
  display: flex;
  flex-direction: column;
}

.list-user-gallery {
  text-align: end;
  padding-top: 50px;
}

.list-user-gallery1 {
  text-align: end;
  padding-top: 40px;
}

p.para-popup {
  padding-bottom: 24px;
}

.list-user-gallery1 li {
  list-style: none;
  padding: 6px 0;
  font-size: 15px;
  font-weight: bold;
}

.list-user-gallery li {
  list-style: none;
  padding: 6px 0;
  font-size: 15px;
  font-weight: bold;
}

.pehragraph-upload-gallery {
  padding-top: 8px;
}

.button-box-upload-gallery {
  margin-top: 10px;
}

.popup-1 .modal-content {
  border-radius: 2.3rem;
}

img.chcked-detail-main-img {
  width: 178px;
  height: 156.92px;
  margin: 45px 0;
}
.select-detail-universal-row-box {
  padding-bottom: 64px;
  min-height: auto;
}
.home-universal-row-box {
  padding-bottom: 50px;
}

.popup-2 .modal-content {
  background-image: url("../Assets/Images/submit-popup.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2.3rem;
  height: 304px;
}

.popup-good .modal-content {
  /* background-image: url("../Assets/Images/popup-home-main.png"); */

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2.3rem;
}
.popup-existing-profile .modal-content {
  background-image: none !important;
}
button.btn.modal-close {
  position: absolute;
  right: 0;
  top: -3px;
  background-color: #ee2c46;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

i.bi.bi-x-lg {
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}

.bi span {
  font-size: 12px;
}
.bi-reg span {
  font-size: 12px;
}

button.bi > span.bi {
  font-size: 12px;
  color: black;
  text-decoration: underline;
}

button.btn.btn-primary.btn-good {
  margin-top: 30px;
  margin-bottom: 16px;
}

button.btn.btn-primary.button-blank-box.exit-popup {
  font-weight: 800;
  color: #ee2c46;

  background-color: transparent;
  border: 1px solid;
}

.splash-screen-center {
  justify-content: center;
  height: 100vh;
  max-height: 640px;
}

.pehragraph-splash {
  padding-top: 0px;
  margin: 0;
  font-size: 1rem;
}

p.text-1.paragraph-text.pehragraph-splash.pehra-exit {
  color: white;
  font-weight: 400;
  font-size: 18px;
}

button.button-accept.button-blank-box.button_exit {
  background-color: white;
  color: #ee2c46;
}

h2.popup-title-top {
  margin-top: -10px;

  font-size: 26px;
  font-weight: 800;
}
.main-existing-popup-title {
  margin-bottom: 35px !important;
}
h2.popup-title-top2 {
  font-size: 26px;
  font-weight: 800;
}

.modal-body button {
  width: 189px;
  height: 50px;
  background-color: #ee2c46;
  border: none;
  padding: 6px 25px;
  color: white;
  border-radius: 50px;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #dd3a3a;
  border-color: transparent;
  box-shadow: none;
}

.gallery-peh .profile-para-2 {
  font-size: 16px;
  font-weight: 500;
}

.gender-1-text {
  padding-top: 10px;
  font-size: 1.5rem;
}

.blank-button-universal {
  background-color: transparent !important;
  color: #404040 !important;
  border: 1px solid #404040 !important;
}

.blank-button-universal:hover {
  background-color: #ee2c46;
  color: white;
}

.line-indicator-box {
  width: 100%;
  box-sizing: border-box;
  margin: 50px 0 17px 0;
  padding: 0px;
}

.select-height-line-indicator {
  margin: 45px 0;
}

.line-indicator-box .row {
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.line-indicator-box .indicator-fill {
  background-color: #ee2c46;
  height: 4px;
  border-radius: 10px;
  width: 8%;
}

.line-indicator-box .indicator-blank {
  height: 4px;
  border-radius: 10px;
  background-color: rgba(238, 44, 70, 0.3);
  width: 8%;
}

.price-fill-blank-box {
  color: rgba(238, 44, 70, 0.3);
  font-size: 1.7rem;
  font-weight: 900;
  margin-left: 5px;
}
img.close-btn {
  width: 24px;
}

.col-12.price-fill-box {
  font-size: 2rem;
  font-weight: 900;
  color: #dc3545;
}

.price-scroll-box.container {
  margin: 10% 0;
}

.select-image-indicator-box {
  margin: 5% 0;
}

.main-home-back-screen1 {
  background-size: 100% 100%;
}

.main-home-back-screen1 {
  width: 100%;

  margin: 0 auto;
  background-image: url("../Assets/Images/main-home-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: auto;
  min-height: 100vh;
  max-height: 100%;
}

.scroller-height {
  height: 225px;
  width: 100%;
  background-color: white;
  overflow: hidden;
  margin: 20px 0;
}
.select-height-universal-row-box {
  padding-bottom: 80px;
}
.picker-container {
  /* overflow: hidden !important; */
}

.picker-container .picker-inner {
  -webkit-mask-box-image: linear-gradient(
    0deg,
    transparent,
    transparent 20%,
    #fff 30%,
    #fff 70%,
    transparent 75%,
    transparent
  ) !important;
}

.picker-container .picker-column .picker-item.picker-item-selected {
  color: #ee2c46 !important;
  font-size: 38px;
  font-weight: 700;
}

.picker-container .picker-column {
  flex: unset !important;
}

.picker-container .picker-column .picker-item {
  position: relative;
  padding: 0 10px;
  white-space: nowrap;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: white;
  width: 250px;
  font-size: 30px;
  font-weight: 700;
}

/* .reactEasyCrop_Container .reactEasyCrop_CropArea {
  width: 240px !important;
  height: 240px !important;
} */

.round-button-select-data {
  position: absolute;
  bottom: 0px;
  right: 0;
  background-color: #ee2c46;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  border-radius: 50%;
  line-height: 0;
  margin-right: -8px;
  margin-bottom: -10px;
  z-index: 99;
}

.round-button-select-data i {
  font-size: 0.9rem;
}

img.ProposalIrrelevant-logo {
  height: 31.011798858642578px;
  width: 129.94815063476562px;

  margin-top: 73px;
}

img.decline-logo {
  width: auto;
  height: 31.01px;
}

img.decline-image {
  margin-top: 30px;
  height: 208.96775817871094px;
  width: 260.29315185546875px;
}

.h2-decline h2 {
  font-size: 26px;
  font-weight: 800;
  margin-top: 44px;
}

.decline-description {
  font-size: 15px;
  font-weight: 400;
  margin-top: 5px;
  padding-bottom: 100px;
}
.decline-description p {
  width: 70%;
  margin: 0 auto;
}

.match-logo2 {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 81px;
  width: 345px;
  justify-content: center;
  background-color: #ee2c46;
  color: white;
  display: flex;
  align-items: center;
  text-align: center;
}

img.MatchSuccess-logo {
  height: 31.011798858642578px;
  width: 129.95px;
  color: white;
}

.container.rain-icon {
  width: 225px;
  height: 90px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 1px 1px 4px #888888;
}

.container.rain-icon .row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

h2.h2-ran {
  font-size: 20px;
  font-weight: 800;
  direction: rtl;
  text-align: start;
  margin: 0;
}

p.see-full-profile {
  color: #404040;
  font-size: 12px;
  text-align: start;
  direction: rtl;
}

button.MatchSuccess-button-save {
  margin-top: 60px;
  height: 53px;
  width: 199px;
  top: 716px;
  border-radius: 390px;
  background: #ee2c46;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 700;
}

.text-icon-save-contact {
  margin-top: 41px;
}

.icon-add {
  margin-top: 60px;
  font-size: 20px;
}

.main-home-logo-bg {
  width: 100%;
  background-color: #ee2c46;
  border-radius: 0px 0px 30px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 108px;
}

.main-home-logo-bg .main-web-home-logo {
  margin-top: 8px;
  margin-bottom: 30px;
}

.main-home-web-back-set {
  background-image: none !important;
}

.time-set-clock-container {
  margin-top: -20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.time-set-clock-row {
  background-color: white;
  width: 225px;
  height: 52px;
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 25px;
  box-shadow: 0.4px 0.4px 4px #888888;
  margin: -30px auto 0 auto;
}

.col-12.main-top-logo-time-box {
  padding: 0;
}
.left-plus-home-web-icons {
  position: absolute;
  top: 43px;
  left: 122px;
}
.right-plus-home-web-icons {
  position: absolute;
  bottom: 54px;
  right: 158px;
}
.time-set-clock-row h6 {
  color: #ee2c46;
  font-weight: bold;
  margin: 0;
  padding: 0;
  font-size: 32.4px;
}
p.para-user-profile {
  padding-bottom: 30px;
}

.time-set-title-col {
  text-align: center;
  margin: 50px 0 0px 0;
}

.time-set-title-col h2 {
  font-size: 26px;
  font-weight: bold;
  line-height: 34px;
  width: 248px;
  height: 68px;
  color: #404040;
}

.main-home-image-slider-container {
  background-color: white;
  border-radius: 45px;
  padding: 0 0px 0px 0px;
  box-shadow: 0.1px 0.1px 12px #d1d1d1;
  height: auto;
  width: 100%;
  max-width: 324px;
  min-height: 300px;
}

.main-home-button-col {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.main-home-button-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 25px;
}

.main-home-image-slider-row {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-home-image-slider-col {
  padding: 0;
}

.main-home-profile-img-slide {
  border-radius: 45px 45px 0 0px;
  width: 101%;
}
.main-img-heartpopup img {
  border-radius: 50%;
  margin: 0 auto;
  width: 77%;
}

.main-home-text-position-seting {
  width: 50%;
  right: 20px;
  left: auto;
  text-align: start;
  top: 10px;
  /* bottom: 50px; */
  z-index: 1;
  padding-bottom: 2rem;
}

.main-home-text-position-seting h5 {
  font-size: 20px;
  direction: rtl;
  color: white;
  font-weight: 700;
}

.main-home-text-position-seting p {
  font-weight: 400;
  font-size: 14px;
  color: white;
  direction: rtl;
}

.main-home-tags-selected-data-col {
  background-color: white;
  height: 24px;
  width: fit-content;
  border-radius: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre;
  margin: 5px 0 0 5px;
}

.main-home-tags-selected-data-col p {
  font-size: 12px;
}

.main-home-tags-selected-data-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.carousel-indicators [data-bs-target] {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  padding: 0;
  opacity: 0.5;
  border-top: 0;
  border-bottom: 0;
}

.carousel-indicators .active {
  opacity: 1;
  width: 18px;
  height: 6px;
  border-radius: 20px 20px 20px 20px;
  background-color: #ee2c46;
}

.carousel-indicators {
  margin-right: 0px;
  margin-bottom: 1rem;
  margin-left: 0px;
}

.main-home-button-indicator-row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  direction: rtl;
}

.main-home-video-row {
  width: 284px;
  margin: 21px auto 10px auto;
  background-color: #f4f4f4;
  border-radius: 15px;
  padding: 7px 0;
}

.main-home-video-col h6 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #404040;
}

.main-home-video-col p {
  font-size: 12px;
  color: #404040;
}

.main-home-video-col-bottum {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-home-video-col-bottum button i {
  font-size: 2rem;
  color: #404040;
}

.main-home-button-row button {
  background-color: #ee2c46;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin: 0 10px;
  border: none;
  line-height: 0px;
}

.main-home-button-row .main-home-btn-deselect-profile {
  background-color: #ffd4da;
}

.main-home-button-row .main-home-btn-deselect-profile i {
  color: #dc3545;
  font-size: 1.7rem;
  font-weight: bold;
}

.main-home-button-row button i {
  color: white;
  font-size: 24px;
}

/* main home web css end */
/* heart popup css start */
.main-img-heartpopup {
  height: 160px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heart-popup-button-box button {
  margin: 10px 0;
}

button.btn.btn-primary.popup-btn-submit {
  background-color: rgba(64, 64, 64, 0.5);
}

.modal-body .heart-popup-pehra-text {
  padding: 0px;
  margin-bottom: 10px;
}
.existing-modal-body-popup {
  padding-top: 0px;
}
.modal-body .heart-popup-heading-text {
  font-weight: bold;
  margin-top: 10px;
}

.heart-popup2-options-box {
  width: 100%;
}

.heart-popup2-options-box .heart-select-options1-box {
  display: flex;
  justify-content: flex-start;

  align-items: center;
  margin: 5px 0;
  height: 50px;
  background: rgba(64, 64, 64, 0.05);
  border-radius: 50px;
  padding: 0 15px;
  font-weight: 400;
  color: #707070;
  direction: rtl;
}

.heart-popup-modal-content {
  border-radius: 45px;
  background-image: url("../Assets/Images/bg.png") !important;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: 58% 5%;
}

.modal-content.heart-popup-modal-content {
  height: 427px;
  width: 324px;
  border-radius: 30px;
}

.modal-content.heart-popup-modal-content-2 {
  height: 494px;
  width: 324px;
  border-radius: 30px;
}

/* gender button css */
button.button-accept.profile-button-box {
  background-color: #9a9a9a;
  margin-top: 50px;
}

/* heart popup css end */

.col-12.matchup-col.matchup-col-select-height {
  justify-content: flex-start !important;
  min-height: auto;
}

.reactEasyCrop_Container {
  margin-top: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker-container {
  height: 300px;
  /* position: relative; */
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  /* z-index: 99999999999; */
  background-color: transparent !important;
}

/* proposel lirrelevant css start */
.h2-ProposalIrrelevant span {
  font-weight: 800;
}
.h2-ProposalIrrelevant .h2-Ren-said-yes {
  font-weight: 800;
}
.h2-ProposalIrrelevant h2 {
  font-weight: 400;
  font-size: 26px;
  margin-top: 45px;
}
.h2-ProposalIrrelevant-img-bott-text h2 {
  font-size: 26px;
  font-weight: 800;
  margin-top: 45px;
}
p.match-success-title {
  font-size: 26px;
  font-weight: 400;
}

img.ProposalIrrelevant-logo {
  height: 31.011798858642578px;
  width: 170.948151px;

  margin-top: 73px;
}

img.ProposalIrrelevant-image {
  margin-top: 35px;
  height: 246px;
  width: 174px;
  height: 171.05px;
}
.para2-ProposalIrrelevant {
  font-size: 16px;
  font-weight: 400;

  margin-top: 2px;
}

img.ProposalIrrelevant-icon {
  height: 36px;
  width: 36px;
  border-radius: 0px;
  margin-top: 30px;
}
.para1-ProposalIrrelevant {
  min-height: 152px;
  background-color: #f5f5f5;
  border-radius: 10px;
  height: auto;
  width: 95%;
  margin-top: 30px;
}
.para1-ProposalIrrelevant h4 {
  font-weight: 800;
  margin-top: 25px;
  font-size: 16px;
}
.para1-ProposalIrrelevant p {
  margin: 20px 20px 0 20px;
  font-size: 16px;
  font-weight: 400;
}
.ProposalIrrelevant-description1 {
  font-size: 16px;
  font-weight: 700;
  margin-top: 51px;
}
.ProposalIrrelevant-description1 p {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 400;
}
.ProposalIrrelevant-description2 {
  font-size: 16px;
  font-weight: 700;
  margin-top: 2px;
}

button.ProposalIrrelevant-button-save {
  height: 53px;
  width: 199px;
  top: 716px;
  border-radius: 390px;
  background: #ee2c46;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 700;
}

/* proposel lirrelevant css end */

/* matchup success css start */
.match-success-home-logo {
  height: auto;
  width: 129.95px;
  margin-top: 0px;
}
.main-mach-succ-logo-bg {
  width: 100%;
  background-color: #ee2c46;
  border-radius: 0px 0px 30px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}
.mat-succ-data-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.matchup-text-img-bottum-text {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(238, 44, 70, 0.2);
  border-radius: 10px;
  width: 259px;
  height: 52px;
}
.matchup-text-img-bottum-text p {
  color: rgba(64, 64, 64, 1);
  font-weight: 400;
  font-size: 26px;
  margin: 0;
  direction: ltr;
}
.matchup-text-img-bottum-text i {
  font-size: 2.2rem;
  padding: 0px 5px;
  color: #dc3545;
}

.matchup-sccess-main-image {
  height: 158px;
  width: 158px;
  margin-top: 60px;
}
.h2-ProposalIrrelevant-img-bott-text h2 {
  margin-top: 20px;
}
.main-home-web-back-set {
  background-image: url("../Assets/Images/matcup-success.png") !important;
  background-size: 100% 100%;
}
.match-success-btn {
  margin-top: 38px;
  margin-bottom: 28px;
  height: 53px;
  width: 225px;
  top: 716px;
  border-radius: 390px;
  border: 1px solid #dc3545;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  background: rgba(238, 44, 70, 1);
}
.picker-container .picker-highlight:after,
.picker-container .picker-highlight:before {
  background-color: transparent !important;
}
.splash-auto-open-popup .modal-content {
  border: none;
  border-radius: 30px !important;
  outline: 0;
}

.splash-modal-close-btn {
  padding: 0px !important;
}
.popup-good.popup-existing-profile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  background-color: black;
  width: 100%;

  margin: 0 auto;
  opacity: 0.7;
  z-index: 999;
  min-width: 375px;
}
/* div#root {
  max-width: 100%!important;
} */
.home-universal-container-box {
  height: 100vh;
}
.splash-auto-open-popup {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  overflow: hidden;
}

@media (max-width: 512px) {
  .scroller-height {
    margin: 0px;
  }
  .row.main-home-gender2-page {
    padding-bottom: 85px;
  }
  .mat-succ-universal-row-box {
    padding-bottom: 50px;
  }
  .modal {
    top: 0;
  }

  div#exampleModal2 {
    margin-top: 0% !important;
  }
}

/* loader css */
.loader1.save-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal.show .modal-dialog {
  transform: none;
  height: 100vh;

  position: relative;
  padding: 0;
  margin: 0 auto;
  width: 100%;
}
.modal-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90px;
  width: 160px;
}
.modal-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;

  border-radius: 0.3rem;
  outline: 0;
  width: 180px;
  height: 100px;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.modal-content.forward-content {
  height: 304px;
  width: 324px;
}
.modal-content.exit-content {
  height: 326px;
  width: 324px;
}
.modal-content.splash-modal-content {
  height: 304px;
  width: 324px;
}
.modal-content.splash-modal-content-2 {
  height: 326px;
  width: 324px;
}

.reload-page-again {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.reload-page-col {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  min-height: 100vh;
  position: relative;
}
#main-web-home-carousel-iteam {
  height: 325px;
}

/* .modal-content .popop-fill-data{
  width:250px;
  height: 325px;
} */
.popop-fill-data .modal-content {
  height: 304px;
  width: 324px;
  border-radius: 2.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.Fill-data-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Fill-data-button .btn-primary {
  padding: 6px 50px;
  border-radius: 50px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #ee2c46;
  border-color: #ee2c46;
  font-weight: 600;
}

.Filling-content h5 {
  margin: 48px 0;
  font-size: 27px;
  font-weight: 700;
}

.terms_popup {
  display: flex;
  justify-content: center;
  align-items: center;
}
.terms_popup p {
  margin: 0 15px;
}
.terms_popup input {
  height: 25px;
  width: 25px;
}
.checkbox:checked:before {
  background-color: green;
}

img.terms-check {
  width: 28px;
  height: 25px;
}

.modal-header.term-icon {
  display: flex;
  justify-content: center;
}
img.termsIcon {
  width: 27px;
  height: 34px;
  margin-bottom: 10px;
}

/* this terms modal css */

/* .popup-3 .modal-content {
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2.3rem;
  height: 325px;
} */
.term-popup-pehra-box span {
  border-bottom: 1px solid black;
  padding-bottom: 2px;
}

.popup-4 .modal-content {
  overflow: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2.3rem;
  height: 555px;
}

h2.term-heading {
  margin-top: 28px;
}
.upload-modal-gallery-modal-box {
  align-items: flex-end;
}
.upload-modal-gallery-modal-box ul {
  margin: 0px;
  padding: 0px;
  text-align: right;
}
.upload-modal-gallery-modal-box ul li {
  list-style: none;
}
.upload-modal-gallery-modal-box ul li h2 {
  font-size: 26px;
  font-weight: 800;
  line-height: 36px;
  width: 250px;
}
.list-button-box-upload-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-button-box-upload-gallery button {
}
.reverse-text-upload-gallery {
  text-align: right;
  font-weight: 700;
  padding: 10px 0 30px 0;
}

/* match success css start */
.whatsap-image {
  margin-top: 5px;
}
img.image-for-whatsap {
  width: 60.65px;
  height: 61px;
}

/* Slider css begins  */
/* 
.container {
  
  background-size:cover;
  min-height:100vh;
  display:flex;
  align-items:center;
  justify-content:flex-start;
}
.brightness-box {
  font-size: 23px;
  border-radius: 8px;
  padding: 0 20px;
  position: absolute;
  bottom: 30%;
  z-index: 9999;
  left: -22%;
}

.brightness-box i {
  margin:0 10px;
}

#range {
  width: 130px;
  height: 10px;
  -webkit-appearance: none;
  background: #ffff;
  outline: none;
  border-radius: 5px;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);     
}
#range::-webkit-slider-thumb {
  -webkit-appearance:none;
  background:gray;
  width:25px;
  height:10.2px;
  border-radius:8px; 
  cursor:pointer;
  transition: 0.5s all ease;
} */
/* slider css ends  */

/* music play animation css start  */

.playing {
  position: relative;
  left: 50%;
  top: 40%;
  width: auto;
  transform: translateX(-50%) translateY(-50%);
}
.playing:after {
  /* content: "Now Playing."; */
  display: block;
  width: 100%;
  margin-top: 10px;
}
.now.playing .bar {
  display: inline-block;
  position: relative;
  margin-right: 1px;
  width: 8px;
  height: 1px;
  overflow: hidden;
  background: linear-gradient(to bottom, #ee2c46, #e75266);
  color: transparent;
  animation-name: pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.paused.playing .bar {
  animation-iteration-count: 1;
}
/* .paused.playing:after {
  content: "Paused (Lame)"
} */
.n1 {
  animation-delay: 0.5s;
}
.n2 {
  animation-delay: 0.2s;
}
.n3 {
  animation-delay: 1.2s;
}
.n4 {
  animation-delay: 0.9s;
}
.n5 {
  animation-delay: 2.3s;
}
.n6 {
  animation-delay: 1.3s;
}
.n7 {
  animation-delay: 3.1s;
}
.n8 {
  animation-delay: 1.9s;
}
@keyframes pulse {
  0% {
    height: 1px;
    margin-top: 0;
  }
  10% {
    height: 30px;
    margin-top: -30px;
  }
  50% {
    height: 10px;
    margin-top: -10px;
  }
  60% {
    height: 10px;
    margin-top: -10px;
  }
  80% {
    height: 40px;
    margin-top: -40px;
  }
  100% {
    height: 1px;
    margin-top: 0;
  }
}
button {
  border: none;
  background: none;
  cursor: pointer;
}
/* music play animation css end  */
.matchup-success-img-box-custum {
}
.matchup-success-img-box-custum img {
  border-radius: 50%;
}

/* signin css start */

.home-universal-col-box .container-fluid {
  padding: 0px !important;
}

.signin-screen-custum-design-row .custom-field {
  position: relative;
  font-size: 14px;
  height: 110px;
  margin: 16px auto 0 auto;
}

.signin-screen-custum-design-row .custom-field input {
  border: none;
  appearance: none;
  background: #f2f2f2;
  padding: var(--field-padding);
  border-radius: 30px;
  width: 300px;
  outline: none;
  font-size: 14px;
  /* direction: rtl; */
}
.signin-screen-custum-design-row .custom-field .placeholder {
  position: absolute;
  right: var(--field-padding);
  width: calc(100% - (var(--field-padding) * 2));
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  top: 22px;
  line-height: 100%;
  transform: translateY(-50%);
  color: #aaa;
  transition: top 0.3s ease, color 0.3s ease, font-size 0.3s ease;
}

.signin-screen-custum-design-row .custom-field input.dirty + .placeholder,
.signin-screen-custum-design-row .custom-field input:focus + .placeholder,
.signin-screen-custum-design-row
  .custom-field
  input:not(:placeholder-shown)
  + .placeholder {
  top: -10px;
  font-size: 10px;
  color: #222;
}
/* ONE */
.signin-screen-custum-design-row .custom-field.one input {
  background: none;
  border: 2px solid #ddd;
  transition: border-color 0.3s ease;
  direction: ltr;
}
.signin-screen-custum-design-row .custom-field.one .country-list .search input {
  width: 100%;
}

.signin-screen-custum-design-row .custom-field.one input + .placeholder {
  right: 18px;
  padding: 0 5px;
}

.signin-screen-custum-design-row .custom-field.one input.dirty,
.signin-screen-custum-design-row
  .custom-field.one
  input:not(:placeholder-shown),
.signin-screen-custum-design-row .custom-field.one input:focus {
  border-color: #222;
  transition-delay: 0.1s;
}

.signin-screen-custum-design-row .custom-field.one input.dirty + .placeholder,
.signin-screen-custum-design-row
  .custom-field.one
  input:not(:placeholder-shown)
  + .placeholder,
.signin-screen-custum-design-row .custom-field.one input:focus + .placeholder {
  top: 0;
  font-size: 10px;
  color: #222;
  background: #fff;
  width: auto;
}

.signin-screen-custum-design-row-err .custom-field.one input.dirty,
.signin-screen-custum-design-row-err
  .custom-field.one
  input:not(:placeholder-shown),
.signin-screen-custum-design-row-err .custom-field.one input:focus {
  border-color: rgb(208, 7, 7);
  transition-delay: 0.1s;
}
/* .signin-screen-custum-design-row-err .smsotp-screen-custum-design-input-box input{

} */
.signin-screen-custum-design-row-err
  .smsotp-screen-custum-design-input-box
  input {
  border-color: red !important;
  color: red;
}
.universal-center-box-data {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  margin: 0 auto;
  width: 100%;
}

.signin-screen-custum-design-row h2 {
  font-size: 26px;
  font-weight: 800;
  margin-top: 70px;
  color: #404040;
}
.signin-screen-custum-design-img-box {
  height: 210px;
  width: 235px;
  position: relative;
  margin: 55px 0 35px 0;
}
.signin-screen-custum-design-img-box img {
  width: 100%;
  height: auto;
}
.signin-screen-custum-design-input-box {
  text-align: right !important;
}

.signin-screen-custum-design-input-box
  .react-tel-input
  .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border-color: transparent !important;
  box-shadow: none !important;
}
.signin-input-box-top-text h4 {
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
}
.signin-screen-convert-english {
  margin: 30px 0 0 0;
}
.signin-screen-convert-english a {
  color: #ee2c46;
}
.signin-screen-custum-design-input-button-box button {
  width: 195px;
  height: 50px;
  border-radius: 50px;
  background-color: #ee2c46;
  border: none;
  margin-top: 25px;
  font-size: 16px;
  font-weight: 600;
}
.signin-screen-custum-design-input-button-box .signin-button-box:focus {
  color: #fff;
}

.signin-screen-custum-design-input-box .react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  right: 20px;
  border-radius: 3px 0 0 3px;
  z-index: 9999999999999999999999999;
}
.signin-screen-custum-design-input-box .react-tel-input .country-list {
  right: -20px;
}
.signin-screen-custum-design-input-box .react-tel-input .form-control {
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 30px !important;
  width: 300px;
  outline: none;
  padding: 18px;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
  color: #495057;
  height: 50px;
  margin: 0px !important;
}
.error-message-box-signin {
  text-align: center;
  color: red;
  padding: 10px 0 0 0;
  width: 100%;
  margin: 0 auto;
  height: auto;
}

/* signin css end  */

/* otp screen css start */

.signin-screen-custum-design-row .smsotp-input-box-top-text {
}
.signin-screen-custum-design-row .smsotp-input-box-top-text h2 {
  width: 75%;
  font-size: 26px;
  font-weight: 800;
  margin: 55px auto 5px auto;
}
.signin-screen-custum-design-row .smsotp-input-box-top-text p {
  font-size: 13px;
  font-weight: 300;
  margin: 10px 0;
}
.smsotp-screen-custum-design-img-box h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24.48px;
  margin-bottom: 20px;
  margin-top: 101px;
}

.smsotp-screen-custum-design-input-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 90px;
}

.smsotp-screen-custum-design-input-box input {
  height: 50px;
  width: 45px;
  border-radius: 5px;
  background: #f5f5f5;
  border: 1px solid #9a9a9a !important;
  margin: 0 4px;
  text-align: center;
  font-size: 1.6rem;
  border: none;
}
.smsotp-screen-custum-design-input-box-err input {
  border: 1px solid red !important;
}
.smsotp-screen-convert-english {
  height: 20px;
}
.smsotp-screen-convert-english h4 {
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}
.smsotp-screen-convert-english h4 a {
  font-weight: 800;
  margin-right: 5px;
  color: #000000;
}
.universal-background-top-btn {
  position: relative;
  height: 207px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 400px;
  margin: -80px 0 0 0;
  z-index: -1;
}
.universal-background-top-btn .universal-background-top-btn-img {
  height: 100%;
  width: auto;
}
.smsotp-screen-show-error-text-box {
  height: 50px;
}
.smsotp-screen-show-error-text-box p {
  font-size: 14px;
  color: #f93751;
  font-weight: 400;
  display: none;
}

.signin-screen-custum-design-row .smsotp-button-box:focus {
  color: #fff !important;
}
/* otp screen css end */

/* personal area css start */
.personal-area-home-header-box {
}
.signin-screen-custum-design-row .personal-area-top-heading-text-box {
  margin-top: 70px;
}
.personal-area-home-header-box .personal-logo-side-button {
  position: absolute;
  left: -2px;
  top: -1px;
  width: 71px;
  height: 155px;
  background: rgba(64, 64, 64, 0.05);
  border-radius: 7px;
  padding: 0 !important;
}

.personal-logo-side-button button {
  width: 100% !important;
}

/* .personal-area-home-header-box .personal-logo-side-button button {
  width: 30px;
  height: 30px;
  position: relative;
} */
.personal-area-home-header-box .personal-logo-side-button button img {
  width: 100%;
}
.personal-input-box-switch {
}
.personal-input-box {
  flex-direction: row;
  margin: 10px 0 10px 0;
}
.personal-input-box label {
  font-size: 14px;
  margin: 0 5px;
  font-weight: 900;
}
.personal-input-box .personal-left-text-switch-box {
  color: #d9d9d9;
}
.personal-input-box .personal-right-text-switch-box {
  color: #404040;
}
.personal-input-box .switch {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 35px;
}

.personal-input-box .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.personal-input-box .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ee2c46;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.personal-input-box .slider:before {
  position: absolute;
  content: "";
  height: 31px;
  width: 31px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

.personal-input-box input:checked + .slider {
  background-color: gray;
}

.personal-input-box input:focus + .slider {
  box-shadow: 0 0 1px #fff;
}

.personal-input-box input:checked + .slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

/* Rounded sliders */
.personal-input-box .slider.round {
  border-radius: 34px;
}

.personal-input-box .slider.round:before {
  border-radius: 50%;
}
.personal-area-screen-custum-design-img-box {
  height: 158px;
  width: 158px;
  position: relative;
  margin: 50px 0;
}
.personal-area-screen-custum-design-img-box img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}
.personal-area-screen-custum-design-img-box h4 {
  font-size: 26px;
  font-weight: 800;
  margin-top: 7px;
}
.personal-area-modal-body-box .close-cross-btn-personal-popup {
  height: 30px !important;
  width: 30px !important;
  padding: 0px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -6px;
  right: -6px;
}
.personal-area-modal-body-box .close-cross-btn-personal-popup i {
}
.personal-area-modal-body-box {
  justify-content: flex-start;
}
.personal-area-modal-content-box .modal-content {
  background-color: #fff;

  border-radius: 0.3rem;

  width: 324px;
  height: 427px;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  box-shadow: 0px 24px 54px rgba(0, 0, 0, 0.45);
  border-radius: 30px;
}
.personal-area-modal-body-box .personal-Area-button-fill-box {
  background-color: #ee2c46;
  border: 1px solid #ee2c46;
  color: #fff;
  margin: 15px 0;
}
.personal-area-modal-body-box .personal-Area-button-blank-box {
  background-color: transparent;
  border: 1px solid #ee2c46;
  color: #ee2c46;
  margin: 10px 0;
}

.text-box-personal-popup {
}
.text-box-personal-popup h2 {
  font-size: 26px;
  font-weight: 800;
  width: 90%;
  margin: 75px auto 15px auto;
}
.text-box-personal-popup p {
  margin-bottom: 30px;
  margin-top: 20px;
  color: #ee2c46;
  font-weight: 400;
}
.personal-area-button-modal-border-box {
  width: 195px;
  height: 50px;
  border-radius: 50px;
  background-color: transparent !important;
  border: 1px solid #ee2c46 !important;
  color: #ee2c46;
  margin-top: 50px;
  font-size: 16px;
  font-weight: 600;
}
.personal-area-button-modal-border-box:hover {
  background-color: #ee2c46 !important;
  border: 1px solid #ee2c46 !important;
  color: #fff;
}
/* personal area css end */
.personal-splash-screen-custum-design-row {
  justify-content: flex-start;
}
.personal-splash-screen-custum-design-row h2 {
  margin-bottom: 60px;
}
.personal-splash-screen-custum-design-img-box img {
  width: 174px;
  height: auto;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #ee2c46;
  background-color: #ee2c46;
  border-color: transparent;
  box-shadow: none;
}
.personal-input-box .slider.round {
  border-radius: 34px;
  background-color: gray;
}
.personal-input-box input:checked + .slider {
  background-color: #ee2c46;
}

.home-vector-screen-1-img-blur {
  filter: blur(5px);
}
.personal-left-text-switch-box-text-color-dark {
  color: #404040 !important;
}
.personal-right-text-switch-box-text-color-light {
  color: #d9d9d9 !important;
}

/* success modal */
.modal-body.success-modal-body {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 3rem 2rem 1rem 2rem;
}

.modal-content.success-modal-content {
  width: 324px;
  height: 494px;
  background: white;
}

p.success-text-popup {
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #404040;
  flex-direction: column;
  text-align: center;
}

span.match-success-text-3-bold {
  font-weight: 800;
}

h2.success-title-top2 {
  font-size: 26px;
  font-weight: 800;
  line-height: 34px;
}

button.button-exit-success {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
}

img.malefemale-icon {
  width: 136px;
  height: 76px;
}

button.malefemale-btn-2_outline {
  background: white;
  border: 1px solid #ee2c46;
  color: #ee2c46;
  font-weight: bold;
}

p.user-gender.malefemale-text {
  font-weight: bold;
  color: #ee2c46;
}

/* ====new - changes====== */
.modal-content.splash-modal-content-2.modal-content-change {
  border: 2px solid #ee2c46 !important;
  height: 529px !important;
  width: 324px !important;
}
.google-figure img {
  border-radius: 0px;
  max-width: 150px;
  padding-bottom: 16px;
}
.change-btn button.button-accept.button-blank-box.button_exit {
  color: #ee2c46 !important;
  border: 1px solid #ee2c46 !important;
  margin: 0px;
  margin-top: 10px;
}

.main-img-heartpopup.main-img-existing-popup.popup-change img {
  max-width: 143px;
  padding-bottom: 16px;
}

h2.popup-title-top.main-existing-popup-title span:first-child {
  font-size: 22px;
  font-weight: 400;
}

h2.popup-title-top.main-existing-popup-title span:last-child {
  font-size: 24px;
  font-weight: 800;
}

/* Splash */

.modal-content.splash-modal-content.splash-modal-content-new {
  width: 324px;
  height: auto;
  background-repeat: no-repeat;
  background-size: contain !important;
  background-position: unset;
}

.popup-submit-div {
  margin-top: 5rem;
}

p.para-popup.para-popup-new {
  padding-bottom: 1rem;
}

p.para-popup-subtitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  border-radius: 3px;
  background: rgba(238, 44, 70, 0.2);
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: initial;
}

p.para-popup-subtitle span:first-child {
  font-weight: 600;
  font-size: 16px;
}

p.para-popup-subtitle span:nth-child(2),
p.para-popup-subtitle span:last-child {
  font-weight: 400;
  font-size: 16px;
}

/* Reject Popup */
.modal-content.heart-popup-modal-content-2.popup-reject-proposal {
  width: 324px;
  height: auto !important;
}

/* .heart-popup2-options-box.popup-reject-proposal-options {
  width: 100%;
  height: 200px;
  overflow-y: scroll;
} */

.col-4.main-home-tags-selected-data-col.popup-reject-proposal-chips {
  margin: 0.5rem 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: inherit;
  overflow-y: scroll;
  height: 200px;
}

p.popup-reject-proposal-chips-p {
  border: 1px solid #d9d9d9;
  border-radius: 100px;
  padding: 6px 12px 6px 12px;
  margin: 0.3rem;
  font-size: 16px;
  font-weight: 500;
}

.popup-reject-proposal-input {
  margin: 0.5rem 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1.5rem;
}

input#popup-reject-proposal-input {
  border-radius: 50px;
  outline: unset !important;
  width: 100%;
  padding: 1px 12px;
  height: 28px;
  font-size: 16px;
  font-weight: 400;
  background: rgba(64, 64, 64, 0.05);
  border: 1px solid rgba(64, 64, 64, 0.5);
}

button.btn.btn-primary.popup-btn-submit.popup-reject-proposal-btn {
  border: 1px solid rgba(238, 44, 70);
}

.popup-reject-proposal-bottom-text-div {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

img.popup-reject-proposal-bottom-img {
  width: 19px;
  margin-right: 0.5rem;
}






/*  ========= location-changes =========== */
p.text-1.gender-1-text.location {
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
}

.select-content select.form-select {
  border-radius: 50px;
  padding: 7px 85px;
  border: 1px solid #fffbfb00;
  text-align: center;
}

.select-content .form-select:focus {
  box-shadow: none !important;
}

.form-select {
  background-color: #f5f5f5;
  transition: none !important;
}

.button-div.btn-change {
  position: relative;
  z-index: 999;
}

.select-figure img {
  width: 100%;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.image-group1.location-btn {
  position: absolute;
  bottom: 89px;
}

/* ====== occupation-start */

.main-home.location.occupation .col-12.matchup-col.matchup-col-select-height {
  justify-content: flex-start !important;
  min-height: auto;
}

.image-group1.location-btn.occupation {
  bottom: 60px;
}

.select-figure.occupation img {
  width: 100%;
  position: absolute;
  right: 0px;
  /* bottom: -27px; */
}

.select-content.input-field input[type="text"] {
  border-radius: 50px;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  padding: 7px 20px;
  width: 85%;
  outline-color: #f5f5f5;
  direction: rtl;
}

/* ====== occupation-end */

/* ====== Life_sytyle ====== */

.main-home.location.occupation.life_style {
  background-position: bottom;
  height: 100vh !important;
}

.main-home.location.occupation.life_style .list-btn-data-box li {
  padding: 4px 12px;
  border-radius: 44px;
  margin-bottom: 0px !important;
}

.main-home.location.occupation.life_style .list-btn-data-box {
  padding: 0px !important;
}

.main-home.location.occupation.life_style .list-btn-data-box {
  width: 90%;
  margin: 0 auto;
  padding: 80px 0;
  flex-wrap: wrap-reverse;
  justify-content: center;
}


.image-group1.location-btn.lifestyle {
  bottom: 64px;
}

/* ====== Life_sytyle ====== */

/* ======= politics ====== */
.policas-content {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.policas-content p {
  font-size: 14px;
  color: #ee2c46;
  width: 100%;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  cursor: pointer;
  background: #ee2c46;
}
input[type="range"]::-webkit-slider-thumb {
  border: 1px solid #ee2c46;
  width: 15px;
  border-radius: 50px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ee2c46;
}

input[type="range"]::-webkit-slider-runnable-track {
  height: 2px;
}

input[type="range"]::-webkit-slider-thumb {
  margin-top: -7px;
}

.main-home.location.polticias {
  background-position: bottom;
  height: 100vh !important;
}

/* ======= politics ====== */

/* ======= lifet-style-second ====== */
.main-home.location.occupation.life_style.second .image-group1.location-btn {
  position: absolute;
  bottom: 64px;
}
.main-home.location.occupation.life_style.second {
  background-image: none !important;
}

.style-content p {
  padding-bottom: 70px;
  color: #404040;
}
/* ======= lifet-style-second ====== */

/* ======= politics/new-modal ==== */
h2.success-title-top2.change {
  display: none !important;
}
h2.hello {
  font-size: 26px;
  font-weight: 800;
  line-height: 34px;
  margin: 0px;
}
p.new-text-content {
  font-weight: 100 !important;
  font-size: 14px;
  color: #423f3f;
  line-height: 23px;
  text-align: center;
  width: 77%;
}
p.user-gender.malefemale-text.change {
  display: none;
}
button.malefemale-btn-2_outline.change {
  display: none;
}
.modal-content.success-modal-content.change {
  height: 310px;
}
.modal-body.success-modal-body.change {
  padding-top: 0px;
  padding-bottom: 0px;
}

.cross i {
  background: #ee2c46;
  color: #ffff;
  font-size: 26px;
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}

/* ======= politics/new-modal ==== */



/* ====== age-start ======= */
.main-home.age {
  position: relative;
  height: 100vh !important;
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    position: relative;
    min-height: 680px !important;
} 

.image-group1.age {
  position: absolute;
  bottom: 69px;
}


img.universal-logo.logo-margine.select-age-logo {
  margin-top: 60px;
}

.change-age .picker-container .picker-column .picker-item{
  font-size: 33px;
}


.change-age .picker-container .picker-column .picker-item.picker-item-selected{
  font-size: 40px !important;
}

/* ====== age-end ======= */


/* ====== age-rnage-start */
.main-home {
  position: relative;
}
p.rnage-content {
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  color: #404040;
  padding-top: 40px;
  position: relative;
  z-index: 999;
}
.range-wrap.second {
  margin-top: -145px;
}

.main-home.age-range .universal-logo {
  margin-top: 50px;
}

.main-home.age-range .line-indicator-box.container-fluid {
  margin: 30px 0 0px 0;
}
/* ====== age-rnage-end */


.select-content.input-field {
  position: relative;
  z-index: 999;
}

.range-wrap .gender-1-text {
  padding-top: 56px;
}

p.text-1.gender-1-text.location.politics {
  padding-bottom: 8px;
  padding-top: 0px;
  margin-top: -8px;
}

.range-wrap {
  margin-top: -48px;
}

.change-wrap .picker-container .picker-column .picker-item{
  width:100px !important;
}

.change-wrap .first {
  width: 50%;
  float: left;
  margin-top: -10px;
}

.change-wrap .rotation.change-ar {
  margin-top: -50px;
}

.main-home.age  .line-indicator-box {
  margin: 30px 0 0px 0;
}

.main-home.age  p.text-1.gender-1-text {
  padding-top: 0px;
  margin-top: -10px;
}

.main-home.age  .change-age {
  margin-top: -50px;
}


.logo-div.logo-life {
  margin-top: -22px;
}