

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.btn-primary:hover {
  color: #fff;
  background-color: #EE2C46;
  border-color:#EE2C46;
}


.reactEasyCrop_Container {
  position: relative!important;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 282px;
  width: 282px;
}

/* .reactEasyCrop_Contain {
  max-width: none!important;
  max-height: none!important;
  margin: auto;
  height: 282px !important;
  width: auto!important;
  position: relative!important;
 
} */


.loader {
  width:50px;
  height:50px;
  display:inline-block;
  padding:0px;
  opacity:0.5;
  border:3px solid #09acfd;
  -webkit-animation: loader 1s ease-in-out infinite alternate;
  animation: loader 4s ease-in-out infinite alternate;
}
.loader-page-row-box{
  display: flex;
  justify-content:center;
  align-items: center;
}
.loader:before {
 content: " ";
 position: absolute;
 z-index: -1;
 top: 5px;
 left: 5px;
 right: 5px;
 bottom: 5px;
 border: 3px solid #09acfd;
}

.loader:after {
 content: " ";
 position: absolute;
 z-index: -1;
 top: 15px;
 left: 15px;
 right: 15px;
 bottom: 15px;
 border: 3px solid #09acfd;
}

@keyframes loader {
  from {transform: rotate(0deg) scale(1,1);border-radius:0px;}
  to {transform: rotate(360deg) scale(0, 0);border-radius:50px;}
}
@-webkit-keyframes loader {
  from {-webkit-transform: rotate(0deg) scale(1, 1);border-radius:0px;}
  to {-webkit-transform: rotate(360deg) scale(0,0 );border-radius:50px;}
}

.loader1 {
  display:inline-block;
  font-size:0px;
  padding:0px;
}
.loader1 span {
  vertical-align:middle;
  border-radius:100%;
  
  display:inline-block;
  width:10px;
  height:10px;
  margin:3px 2px;
  -webkit-animation:loader1 0.8s linear infinite alternate;
  animation:loader1 1s linear infinite alternate;
}
.loader1 span:nth-child(1) {
  -webkit-animation-delay:-1s;
  animation-delay:-1s;
 background:rgba(245, 103, 115,0.6);
}
.loader1 span:nth-child(2) {
  -webkit-animation-delay:-0.8s;
  animation-delay:-0.8s;
 background:rgba(245, 103, 115,0.8);
}
.loader1 span:nth-child(3) {
  -webkit-animation-delay:-0.26666s;
  animation-delay:-0.26666s;
 background:rgba(245, 103, 115,1);
}
.loader1 span:nth-child(4) {
  -webkit-animation-delay:-0.8s;
  animation-delay:-0.8s;
 background:rgba(245, 103, 115,0.8);
 
}
.loader1 span:nth-child(5) {
  -webkit-animation-delay:-1s;
  animation-delay:-2s;
 background:rgba(245, 103, 115,0.4);
}

@keyframes loader1 {
  from {transform: scale(0, 0);}
  to {transform: scale(1, 1);}
}
@-webkit-keyframes loader1 {
  from {-webkit-transform: scale(0, 0);}
  to {-webkit-transform: scale(1, 1);}
}
.home-footer-box p a {
  color: black;
  margin-right: 8px;
}
                  
.error-input-box-alert{
  border: 1px solid red;
  color: red;
}